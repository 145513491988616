/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@3.6.4/dist/jquery.min.js
 * - /npm/parsleyjs@2.9.2/dist/parsley.min.js
 * - /npm/bootstrap@3.4.1/dist/js/bootstrap.min.js
 * - /npm/bootbox@5.3.4/bootbox.all.min.js
 * - /npm/js-cookie@2.2.1/src/js.cookie.min.js
 * - /npm/cookieconsent@3.1.1/build/cookieconsent.min.js
 * - /npm/parsleyjs@2.9.2/dist/i18n/de.min.js
 * - /npm/parsleyjs@2.9.2/dist/i18n/de.extra.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
